import { sendCaptcha } from '@/api/index.js';
import Toastrs from '@/utils/Toastrs';

export default async ({appid, type, mobile, number, setNum, setCode}: any) => {
    if (number != 60) {
        return;
      }
      const reg = /^1[3456789]\d{9}$/;
      if (!reg.test(mobile)) {
        Toastrs.error(`请输入正确的手机号码`);
        return;
      }
      const rse: any = await sendCaptcha({
        purpose: 'login',
        mobile: mobile,
        channels: type,
        source_appid: appid,
      });
      dunwNumFun(number, setNum);
    if(rse.data && rse.data.code){
      setCode(rse.data.code.toString())
    }

}


function dunwNumFun(number: any, setNum: any) {
  number--;
  setNum(number)
  setTimeout(() => {
    if (number > 1) {
      dunwNumFun(number, setNum);
    } else {
      number = 60;

      setNum(60)
    }
  }, 1000);
}